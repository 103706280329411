@import url('https://fonts.googleapis.com/css?family=Poppins');

@tailwind base;
@tailwind components;
@tailwind utilities;
/* @import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css"; */

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: Poppins, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.codex-editor .codex-editor__redactor {
  padding-bottom: 1rem;
}


